import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
    //修改原型对象中的push方法，replace方法就把VueRouter.prototype.push的push换成replace即可
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [{
        path: '/',
        name: 'Login',
        component: () =>
            import ("@/views/Login.vue")
    },
    {
        path: '/Select',
        name: 'Select',
        component: () =>
            import ("@/views/Select.vue")
    },
    {
        path: '/Home',
        name: 'Home',
        component: () =>
            import ("@/views/Home.vue"),
        children: [
            // 设备管理 
            {
                name: 'DeviceInfoManagement',
                path: '/DeviceInfoManagement',
                component: () =>
                    import ("@/views/manage/DeviceInfoManagement.vue"),
            },
            // 设备类型管理
            {
                name: 'DeviceTypeManagement',
                path: '/DeviceTypeManagement',
                component: () =>
                    import ("@/views/manage/DeviceTypeManagement.vue"),
            },
            // CRT显示
            {
                name: 'CRTShow',
                path: '/CRTShow',
                component: () =>
                    import ("@/views/manage/CRTShow.vue"),
            },
            // CRT管理
            {
                name: 'CRTManagement',
                path: '/CRTManagement',
                component: () =>
                    import ("@/views/manage/CRTManagement.vue"),
            },
            // CRT点位管理
            {
                name: 'CRTCoordinate',
                path: '/CRTCoordinate',
                component: () =>
                    import ("@/views/manage/CRTCoordinate.vue"),
            },
            // 首页CRT点位管理
            {
                name: 'indexCRTCoordinate',
                path: '/indexCRTCoordinate',
                component: () =>
                    import ("@/views/manage/IndexCRTCoordinate.vue"),
            },
            // 报警历史记录
            {
                name: 'History',
                path: '/History',
                component: () =>
                    import ("@/views/history/History.vue"),
            },
            // 历史记录
            {
                name: 'HistoryValue',
                path: '/HistoryValue',
                component: () =>
                    import ("@/views/history/HistoryValue.vue"),
            },
            //一键报警
            {
                name: 'AKeyAlarm',
                path: '/AKeyAlarm',
                component: () =>
                    import ("@/views/manage/AKeyAlarm.vue"),
            },
            // baidu地图
            {
                name: 'MapBuiding',
                path: '/MapBuiding',
                component: () =>
                    import ("@/views/mapBuiding/MapBuidingHome.vue"),
            },
            {
                name: 'carMap',
                path: '/carMap',
                component: () =>
                    import ("@/views/carMap/carMap.vue"),
            },
            {
                // CRT点位管理
                name: 'CRTCoordinate',
                path: '/SelectLoLa',
                component: () =>
                    import ("@/views/mapBuiding/SelectLoLa.vue"),
            },
            {
                // 主页
                path: '/Show',
                name: 'Show',
                component: () =>
                    import ("@/views/Show.vue")
            },
            {
                // 主页
                path: '/ShowMap',
                name: 'ShowMap',
                component: () =>
                    import ("@/views/ShowMap.vue")
            },
            // 订阅管理
            {
                name: 'SubscribeManagement',
                path: '/SubscribeManagement',
                component: () =>
                    import ("@/views/manage/SubscribeManagement.vue"),
            },
            // 权限系统管理
            {
                name: 'PermissionSysManagement',
                path: '/PermissionSysManagement',
                component: () =>
                    import ("@/views/management/PermissionSysManagement.vue"),
            },
            // 权限类型管理
            {
                name: 'PermissionTypeManagement',
                path: '/PermissionTypeManagement',
                component: () =>
                    import ("@/views/management/PermissionTypeManagement.vue"),
            },
            // 权限类型分配
            {
                name: 'PermissionTypeAllot',
                path: '/PermissionTypeAllot',
                component: () =>
                    import ("@/views/management/PermissionTypeAllot.vue"),
            },
            // 通知系统管理
            {
                name: 'NoticeSysManagement',
                path: '/NoticeSysManagement',
                component: () =>
                    import ("@/views/management/NoticeSysManagement.vue"),
            },
            // 通知类型管理
            {
                name: 'NoticeTypeManagement',
                path: '/NoticeTypeManagement',
                component: () =>
                    import ("@/views/management/NoticeTypeManagement.vue"),
            },
            // 通知类型分配
            {
                name: 'NoticeTypeAllot',
                path: '/NoticeTypeAllot',
                component: () =>
                    import ("@/views/management/NoticeTypeAllot.vue"),
            },
            // 公司管理系统
            {
                name: 'Company',
                path: '/Company',
                component: () =>
                    import ("@/views/management/Company.vue"),
            },
            // 账号管理
            {
                name: 'AccountManagement',
                path: '/AccountManagement',
                component: () =>
                    import ("@/views/management/AccountManagement.vue"),
            },
            // 权限分配
            {
                name: 'PermissionAssignment',
                path: '/PermissionAssignment',
                component: () =>
                    import ("@/views/management/PermissionAssignment.vue"),
            },
            // 操作日志
            {
                name: 'OperationLog',
                path: '/OperationLog',
                component: () =>
                    import ("@/views/history/OperationLog.vue"),
            },
        ]
    },
]

const router = new VueRouter({
    routes,
});

export default router;