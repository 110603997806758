import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import * as echarts from 'echarts';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import "element-ui/lib/theme-chalk/index.css";
import 'element-ui/lib/theme-chalk/base.css'
import './assets/static/font_2708870_4xnsr8qlz6h/iconfont.css'
import './assets/css/normalize.css'
import './assets/css/common.css'
import './assets/css/bg.css'
import './assets/css/heartBeat.css'
import 'echarts-gl'
import scroll from 'vue-seamless-scroll'
import dataV from '@jiaminghi/data-view'

import Blob from '@/introduce/Blob'
Vue.use(Blob)
Vue.use(dataV)

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
    ak: 'KxdYSrrPawChQDBqYjxuqNOyISTVfgO2'
})
import VueResource from 'vue-resource'


import  Cookies  from  "js-cookie"
Vue.prototype.$Cookies  =  Cookies



// 引入VueResource
Vue.use(VueResource)
Vue.use(scroll)

// 引入echarts
Vue.prototype.$echarts = echarts

// collapse 展开折叠
Vue.component(CollapseTransition.name, CollapseTransition)

// 全局前置守卫，to是去那个路由，from是从哪儿个路由去的，next是下一步
//  路由判断登录 根据路由配置文件的参数
router.beforeEach((to, from, next) => {
    //  to,将要访问的路径
    // from  从哪个路径跳转过来的
    // next 是一个函数,代表放行
    // let token = sessionStorage.getItem('userName')
    // if (to.path === '/login') {
    // return next()
    // } else {
    //     if (!token) {
    //         return next('/login')
    //     } else {
    next()
        //     }
        // }
})

Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");